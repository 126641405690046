export default class Slideshow {
	init () {
		if ( jQuery( '.c-slider-fullwidth' ).length > 0 ) {

			jQuery( '.c-slider-fullwidth' ).each( function ( id, slider ) {

				jQuery( slider ).find( jQuery( '.c-slider-slick-for' ) ).slick( {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: true,
					dots: true,
					draggable: true,
					accessibility: true,
					infinite: false,
					responsive: [
						{
							breakpoint: 992,
							settings: {
								arrows: false,
								dots: true
							}
						}
					]
				} );
			} )
		}
	}
}