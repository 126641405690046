export default class Testimonials{
    init(){
        jQuery( '.c-testimonials .c-slider-testimonials' ).slick( {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            focusOnSelect: false,
            accessibility: true,
            infinite: false,
            nextArrow: '.c-testimonials .c-testimonials-next',
            prevArrow: '.c-testimonials .c-testimonials-prev',
            responsive: [
                {
                    breakpoint: 639,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        arrows: true,
                        dots: false,
                    }
                },

            ]
        })
    }
}