import AOS from 'aos/dist/aos';
import 'aos/dist/aos.css';
import './vendor/slick.min';

import Faq from './components/faq/view';
import Slideshow from './components/slideshow/view';
import Tabs from './components/tabs/view';
import Testimonials from './components/testimonials/view';
import FullwidthSlider from './components/fullwidth-slider/view';

docReady( function () {

    AOS.init( {
        once: true
    } )

    let faq = new Faq();
    faq.init();   

    let slideshow = new Slideshow();
    slideshow.init();
    
    let fullwidthslider = new FullwidthSlider();
    fullwidthslider.init();
    
    let tabs = new Tabs();
    tabs.init();

    let testimonials = new Testimonials();
    testimonials.init();

} )


function docReady ( fn ) {
    if ( document.readyState === "complete" || document.readyState === "interactive" ) {
        setTimeout( fn, 1 );
    } else {
        document.addEventListener( "DOMContentLoaded", fn );
    }
}